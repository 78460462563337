// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
// SPE Begin custom login and account services
// import LoginService from './account/login.service';
// import AccountService from './account/account.service';
import LoginService from './account/login-extended.service';
import AccountService from './account/account-extended.service';
// SPE End custom login and account services
import AlertService from './shared/alert/alert.service';

// SPE Begin remove bootstrap-sass
// import '../content/scss/global.scss';
// import '../content/scss/vendor.scss';
// SPE End remove bootstrap-sass
// SPE Begin
import '../content/scss/spe/global.scss';
// SPE End
import TranslationService from '@/locale/translation.service';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
// SPE Begin vuetify-import
import vuetify from '../plugins/vuetify';
// SPE End vuetify-import
// SPE Begin portal-vue
import PortalVue from 'portal-vue';
import VueTimers from 'vue-timers';
// SPE End portal-vue

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
// SPE Begin use portal-vue
Vue.use(PortalVue);
// SPE End use portal-vue
// SPE Begin manage timers or intervals
Vue.use(VueTimers);
// SPE End manage timers or intervals
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
// SPE Begin custom login and account services
// const loginService = new LoginService();
// const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router);
const loginService = new LoginService(store);
const accountService = new AccountService(store, router);
// SPE End custom login and account services
router.beforeEach(async (to, from, next) => {
  // BEGIN SPE custom beforeEach
  /*
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
  */
  //if (to.path === "/" ) {
  // console.log("/")
  // next({
  //   path: '/auth'
  // })
  //} else
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!accountService.authenticated) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  // SPE Begin config vuetify
  vuetify,
  // SPE End config vuetify
  provide: {
    loginService: () => loginService,
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        window.location.reload();
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
