import VueI18n from 'vue-i18n';
import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
import { EmployeeAbsenceType, IEmployeeAbsenceType } from '@/shared/model/employee-absence-type.model';
import type { IAlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';

import { AlertMessagePropertiesType, AlertMessageProperties } from '@/shared/model/spe/alert-message-properties.model';

import AlertMessage from '@/core/spe/alert-message/alert-message.vue';
import LoginService from '@/account/login.service';
import { AlertService } from '@gv/ammo-grome';
import { EmployeeAbsence, IEmployeeAbsence } from '@/shared/model/employee-absence.model';
import EmployeeAbsenceTypeService from '@/entities/employee-absence-type/employee-absence-type.service';
import { required } from 'vuelidate/lib/validators';
import LocaleMessage = VueI18n.LocaleMessage;
import EmployeeAbsenceService from '@/entities/employee-absence/employee-absence.service';
import { IEmployee } from '@/shared/model/employee.model';

// eslint-disable-next-line
const validations: any = {
  employeeAbsence: {
    company: {
      required,
    },
    site: {
      required,
    },
    employeeNumber: {
      required,
    },
    employeeFirstName: {
      required,
    },
    employeeLastName: {
      required,
    },
    expectedStartDate: {
      required,
    },
    expectedEndDate: {
      required,
    },
    expectedStartTime: {
      required,
    },
    expectedEndTime: {
      required,
    },
    absenceCode: {
      required,
    },
    absenceDescription: {
      required,
    },
  },
};

// eslint-disable-next-line
// @ts-ignore
@Component({
  components: {
    'alert-message': AlertMessage,
  },
  validations,
})
export default class EmployeeAbsenceUpdate extends Vue {
  @Inject('alertService')
  private alertService!: () => AlertService;

  @Inject('loginService')
  private loginService!: () => LoginService;

  @Provide('employeeAbsenceTypeService')
  private employeeAbsenceTypeService = () => new EmployeeAbsenceTypeService();

  @Provide('employeeAbsenceService')
  private employeeAbsenceService = () => new EmployeeAbsenceService();

  private employeeAbsenceTypes: Array<IEmployeeAbsenceType> = [];

  public employeeAbsence: IEmployeeAbsence = new EmployeeAbsence();

  private isSaving = false;

  private isFetching = false;

  private image = '';

  private modalDates = false;

  private modalHourStart = false;

  private modalHourEnd = false;

  // Selected absenceType
  private fieldAbsenceType: IEmployeeAbsenceType = new EmployeeAbsenceType();

  private fieldDates: Array<string> = [];

  private sameHour = false;

  private alertMessageProperties: IAlertMessageProperties = new AlertMessageProperties();

  public employee: IEmployee = {};

  public descriptionMaxLength = 250;

  created() {
    this.initialize();
    this.retrieveAllEmployeeAbsenceTypes();
  }

  @Watch('fieldDates')
  onFieldDatesChanged(val: Array<string>) {
    this.employeeAbsence.expectedStartDate = val[0] ?? '';
    this.employeeAbsence.expectedEndDate = val[1] ?? '';
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  @Watch('currentLanguage')
  onCurrentLanguageChange() {
    this.retrieveAllEmployeeAbsenceTypes();
  }

  /**
   * Instantiate and initialize the entitiy, update screen fields
   */
  public initialize(): void {
    this.employeeAbsence = new EmployeeAbsence();

    // Reset
    this.alertMessageProperties = new AlertMessageProperties();
    this.fieldAbsenceType = new EmployeeAbsenceType();
    this.fieldDates.splice(0);

    // Initilize
    this.employee = this.$store.getters.employee;
    this.image = this.employee.image.image;

    // Sets employee absence properties

    const employeeName: string[] = this.employee.name.split(' ');

    this.employeeAbsence.company = this.employee.company;
    this.employeeAbsence.site = this.employee.site;
    this.employeeAbsence.employeeNumber = Number(this.employee.number);
    // eslint-disable-next-line
    this.employeeAbsence.employeeFirstName = employeeName[0];
    this.employeeAbsence.employeeLastName = employeeName.slice(1).join(' ');
  }

  public get datesSorted(): Array<string> {
    return EmployeeAbsenceUpdate.sortDateArray(this.fieldDates);
  }

  /**
   * Rename refactoring goHome
   */
  public home(): void {
    this.loginService().logout();
    this.$router.push({ name: 'EmployeeAuthentication' });
  }

  public cancel(): void {
    this.initialize();
    this.$v.$reset();
  }

  /**
   * Rename refactoring submitAbsence
   */
  public save(): void {
    this.employeeAbsenceService()
      .create(this.employeeAbsence)
      // eslint-disable-next-line
      .then(param => {
        this.isSaving = false;
        // this.$router.go(-1)
        // const message = this.$t('marutApp.employeeAbsence.created', { param: param.id })
        const message = this.$t('marutApp.employeeAbsence.createdNoIdentifier');
        // this.alertService().showAlert(this, AlertType.Success, `${message}`)
        this.showHttpSuccess(`${message}`);
      })
      .catch(error => {
        this.isSaving = false;
        // this.alertService().showHttpError(this, error.response)
        this.showHttpError(error.response);
      });
  }

  public get minDate(): string {
    const date = new Date();
    const { daysInAdvance } = this.fieldAbsenceType;
    if (daysInAdvance) {
      date.setDate(date.getDate() + daysInAdvance);
    }
    return date.toISOString().substr(0, 10);
  }

  public get daysInAdvanceHint(): string {
    if (this.fieldAbsenceType.daysInAdvance === undefined) {
      return '';
    }
    return `${this.$t('marutApp.employeeAbsenceType.daysInAdvance')}: ${this.fieldAbsenceType.daysInAdvance}`;
  }

  public get expectedStartDateHint(): string {
    if (this.fieldAbsenceType.daysInAdvance === undefined) {
      return '';
    }
    return `${this.$t('entity.validation.min', { min: this.minDate })}`;
  }

  public retrieveAllEmployeeAbsenceTypes(): void {
    this.isFetching = true;
    this.employeeAbsenceTypeService()
      .retrieve()
      .then(
        res => {
          this.employeeAbsenceTypes = res.data;

          if (this.fieldAbsenceType.code) {
            this.fieldAbsenceType = this.employeeAbsenceTypes.find(it => it.code === this.fieldAbsenceType.code);
          }
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  private static sortDateArray(arr: Array<string>): Array<string> {
    const tmpArr = arr.map(date => ({ str: date, date: new Date(date) }));
    const sortedArray = tmpArr.sort((a, b) => a.date.valueOf() - b.date.valueOf());
    return sortedArray.map(({ str }) => str);
  }

  allowedMinutes(m: number) {
    if (this.sameHour && this.isSameDay) {
      return m % 30 === 0 && m > Number((this.employeeAbsence.expectedStartTime ?? '').split(':')[1]);
    }
    return m % 30 === 0;
  }

  allowedHours(h: number) {
    if (this.isSameDay && this.employeeAbsence.expectedStartTime) {
      return h >= Number(this.employeeAbsence.expectedStartTime.split(':')[0]);
    }
    return h;
  }

  public get isSameDay(): boolean {
    return this.employeeAbsence.expectedStartDate === this.employeeAbsence.expectedEndDate;
  }

  // check if there's a period of time between the start date/time and the end date/time
  checkIfSameHour(endHour: number) {
    const startHour = (this.employeeAbsence.expectedStartTime ?? '').split(':')[0];
    this.sameHour = endHour.toString() === startHour;
  }

  isValidAbsenceFields() {
    // check if any field is empty
    // except description
    if (
      JSON.stringify(this.fieldAbsenceType) === '{}' ||
      JSON.stringify(this.fieldDates) === '[]' ||
      (this.employeeAbsence.expectedStartTime ?? '') === '' ||
      (this.employeeAbsence.expectedEndTime ?? '') === ''
    ) {
      // console.error('invalid')
      return false;
    }
    return true;
  }

  // eslint-disable-next-line
  allowedDates(dateString: string) {
    const date = new Date(dateString);
    return date.getDay() !== 0 && date.getDay() !== 6;
  }

  public setAbsenceType(employeeAbsenceType: IEmployeeAbsenceType): void {
    this.fieldAbsenceType = employeeAbsenceType;

    // Set AbsenceType propertie in employeeAbsence
    this.employeeAbsence.absenceCode = Number(employeeAbsenceType.code) ?? 0;
    this.employeeAbsence.absenceDescription = employeeAbsenceType.description ?? '';
  }

  // eslint-disable-next-line
  public vuelidateErrors(model: any, options: { [k: string]: any }): Array<string | LocaleMessage> {
    // console.log(`Dirty=${model.$dirty}, anyError=${model.$anyError}`);

    // Property received some sort of interaction from the user
    if (!model.$dirty) return [];

    const errors = [];

    // eslint-disable-next-line
    if (model.hasOwnProperty('required') && !model.required) {
      errors.push(this.$t('entity.validation.required'));
    }

    // eslint-disable-next-line
    if (model.hasOwnProperty('minLength') && !model.minLength) {
      errors.push(this.$t('entity.validation.minlength', { min: options.min }));
    }

    // eslint-disable-next-line
    if (model.hasOwnProperty('maxLength') && !model.maxLength) {
      errors.push(this.$t('entity.validation.maxlength', { max: options.max }));
    }

    // eslint-disable-next-line
    if (model.hasOwnProperty('minValue') && !model.minValue) {
      errors.push(this.$t('entity.validation.min', { min: options.min }));
    }

    // eslint-disable-next-line
    if (model.hasOwnProperty('maxValue') && !model.maxValue) {
      errors.push(this.$t('entity.validation.max', { max: options.max }));
    }
    return errors;
  }

  private showHttpSuccess(message: string): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Success;
    this.alertMessageProperties.message = message;
    this.alertMessageProperties.buttonText = `${this.$t('global.menu.home')}`;
    this.alertMessageProperties.visible = true;
  }

  // eslint-disable-next-line
  private showHttpError(httpErrorResponse: any): void {
    this.alertMessageProperties.type = AlertMessagePropertiesType.Error;
    this.alertMessageProperties.message = httpErrorResponse.data.message;
    this.alertMessageProperties.buttonText = `${this.$t('entity.action.back')}`;
    this.alertMessageProperties.visible = true;
  }

  private onAlertMessageClick(): void {
    this.alertMessageProperties.visible = false;

    if (this.alertMessageProperties.type === AlertMessagePropertiesType.Success) {
      this.home();
    }
  }
}
